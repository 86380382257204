export const TRANSLATIONS_DE = {
  email: "E-Mail",
  password: "Passwort",
  address: "Adresse",
  login_credentials: "Anmeldedaten",
  forgotpassword: "Passwort vergessen? ",
  email_field_is_required: "E-Mail-Feld ist erforderlich",
  reset_password: "Passwort zurücksetzen",
  password_field_is_required: "Passwort-Feld ist erforderlich",
  password_do_not_match: "Passwort stimmt nicht überein",
  invalid_email_address: "Ungültige E-Mail-Adresse",
  enter_email_address: "E-Mail Adresse eingeben",
  create_account: "Konto erstellen",
  dont_have_an_account: "Sie haben kein Konto?",
  already_have_an_account: "Sie haben bereits ein Konto?",
  back_to_login: "Zurück zum Login",
  incorrect_email_or_password: "Falsche E-Mail oder Passwort",
  enter_the_email_address_associated_with_your_account_and_we_will_send_you_a_link_to_reset_your_password:
    "Geben Sie die mit Ihrer verknüpfte E-Mail-Adresse ein	Konto und wir senden Ihnen einen Link zum Zurücksetzen	Ihr Passwort.",
  dashboard: {
    title: "Dashboard",
    view_trees: "Bäume anzeigen",
    view_sensors: "Sensoren anzeigen",
  },
  newproject: "Neues Projekt",
  logout: "Abmelden",
  viewproject: "Projekt anzeigen",
  createproject: "Projekt erstellen",
  projectname: "Projektname",
  selecttree: "Baum auswählen",
  user: "Benutzer:in",
  users: "Benutzer:innen",
  username: "Benutzername",
  treereference: "Baumreferenz",

  child_trees: "Repräsentierte Bäume",

  sortoftree: "Baumart",
  save: "Speichern",
  position: "Position",
  Notes: "Notizen",
  addTree: "Baum hinzufügen",
  TreeType: "Baum Typ",
  old: "Alt",
  new: "Neu",
  name: "Name",
  image: "Bild",

  abort: "Abbrechen",
  next: "Weiter",
  back: "Zurück",
  update: "Aktualisieren",
  login: "Anmeldung",
  signup: "Registrierung",
  date_of_planting: "Pflanzdatum",
  supplies: "Lieferungen",
  lightdry: "Leicht Trocken",
  addTreeType: "Füge Baumart hinzu",
  delete: "Löschen",
  deleting: "Löschen ...",
  saving: "Speichern ...",
  updating: "Aktualisieren ...",
  edit: "Bearbeiten",
  project: "Projekt",
  projects: "Projekte",
  add: "Hinzufügen",
  trees: "Bäume",
  tree: "Baum",
  choose_tree: "Wählen Sie einen Baum aus",
  customers: "Kund:innen",
  customer: "Kund:in",
  confirm: "Bestätigen",
  no_connection: "Keine Verbindung",
  battery_status: "Batteriestatus",
  graph: "Grafik",
  page: "Seite",

  health_state: "Baumstatus",
  // health_state: "Gesundheitszustand",
  last_send: "Letztes Senden",
  last_value: "Letzter Messwert",
  start_date: "Startdatum",
  end_date: "Enddatum",
  send: "Senden",
  weather: "Wetter",

  project_details: {
    empty_project: "Leeres Projekt, bitte beginnen Sie mit dem Hinzufügen von Bäumen.",
    empty_trees: "Keine Bäume erstellt, beginnen Sie bitte mit dem Hinzufügen von Bäumen.",
    no_tree_selected: "Bitte Baum auswählen, um Details anzuzeigen",
    no_sensor_selected: "Bitte Sensor auswählen, um Details anzuzeigen",
    empty_location: "Kein Standort gefunden, Bitte Standort hinzufügen",
    empty_sensors: "Keine Sensoren erstellt, begginen Sie mit dem Hinzufügen von Sensoren.",
    no_area_selected: "Bitte Bereich auswählen, um Details anzuzeigen",
  },
  user_details: {
    empty_project: "Kein Benutzer, bitte beginnen Sie mit dem Hinzufügen von Bäumen.",
    no_user_selected: "Bitte Benutzer auswählen, um Details anzuzeigen",
  },
  sensor_details: {
    empty_sensors: "Kein Sensor, bitte beginnen Sie mit dem Hinzufügen von Sensoren.",
    no_sensor_selected: "Bitte Sensor auswählen, um Details anzuzeigen",
    no_data_found: "Keine Sensordaten gefunden",
  },
  sensor: "Sensor",
  sensors: "Sensoren",
  sensor_data: "Sensordaten",
  species: "Art",
  genus: "Gattung",
  actions: "Aktionen",
  battery: "Batterie",

  threshold: "Grenzwert",
  settings: "Einstellungen",
  notifications: "Benachrichtigungen",
  mark_all_as_seen: "Markiere alle als gelesen.",
  showing: "Anzeigen",

  hardware_serial: "DevEUI",
  imei: "IMEI",
  // hardware_serial_no: "Hardware Serial no.",
  add_sensor: "Sensor hinzufügen",
  add_user: "Benutzer hinzufügen",
  edit_user: "Benutzer hinzufügen",
  edit_sensor: "Sensor bearbeiten",

  push_message_notification: "Push-Benachrichtigungen",
  email_notification: "E-Mail-Benachrichtigungen",

  sensor_status_change_to_no_connection: "Sensorstatusänderung: Grau/kein Datenempfang",
  sensor_status_change_to_dry: "Sensorstatusänderung: Rot/Trockenstress",
  sensor_status_change_to_drying_out: "Sensorstatusänderung: Gelb/Leichter Trockenstress",

  treespecies: "Baumart",
  treegenus: "Baumgattung",
  skip: "Überspringen",
  notes: "Anmerkungen",
  latitude: "Breitengrad",
  longitude: "Längengrad",

  trunk_circumference: "Stammumfang",
  planting_year: "Pflanzjahr",
  select_year: "Jahr auswählen",
  crown_diameter: "Kronendurchmesser",
  represent_tree: "Baum darstellen",
  planting_time: "Pflanzzeit",
  altitude: "Höhe",
  height: "Höhe",
  location: "Standort",
  reference: "Referenz",

  invalid_date: "Ungültiges Datum",
  error: {
    this_field_is_required: "Dieses Feld ist erforderlich",
    name_field_is_required: "Namensfeld ist erforderlich",
    please_choose_species: "Bitte die Baumart auswählen",

    please_choose_genus: "Bitte die Baumattung auswählen",
    choose_location: "Standort wählen",
    hardware_serial_no_is_required: "Die Hardware-Seriennummer ist erforderlich",
    a_valid_threshold_is_required: "Ein gültiger Schwellenwert ist erforderlich",
    form: "Die Eingabe der Werte war fehlerhaft. Bitte korrigieren Sie diese und versuchen es erneut.",
  },
  first_name: "Vorname",
  last_name: "Nachname",
  profile: "Profil",
  Profile: "Profil",
  Settings: "Einstellungen",
  Manual: "Benutzerhandbuch",
  of: "von",
  the: "der",
  Menu: "Menü",
  phone_number: "Telefonnummer",
  account_type: "Kontotyp",
  city: "Stadt",
  street_name: "Straße",
  house_number: "Hausnummer",
  country: "Land",
  zip_code: "Postleitzahl",
  day: {
    mon: "Montag",
    tue: "Dienstag",
    wed: "Mittwoch",
    thu: "Donnerstag",
    fri: "Freitag",
    sat: "Samstag",
    sun: "Sonntag",
  },
  end: "Enddatum",
  admin: "Admin",
  basic: "Basis",
  superuser: "Superuser",
  add_existing_tree: "Vorhandenen Baum hinzufügen",
  phase: "Phase",

  health_states: {
    healthy: "Gesund",
    medium: "Mittel",
    critical: "Kritisch",
    frost: "Frost",
    dry: "Trocken",
    disconnect: "Verbindung trennen",
    drying_out: "Austrocknen",
    unknown: "Unbekannt",
  },
  tree_cadastre_no: "Baumkataster Nr.",
  export: "Exportieren",
  problem_in_creating_customer: "Problem beim Anlegen des Kunden",
  problem_in_creating_sensor: "Problem beim Anlegen des Sensors",
  select_customer_type: "Kundentyp wählen",
  assign_to_tree: "dem Baum zuordnen",

  customer_type: "Kundentyp",
  import_trees: "Bäume importieren",
  choose_file: "Datei auswählen",
  upload: "Hochladen",
  choose_the_file_to_be_imported: "Zu importierende Datei auswählen",

  upload_tree: "Baum hochladen",
  change_image: "Bild ändern",
  tree_added_successfully: "Baum erfolgreich hinzugefügt",
  change_language: "Sprache ändern",
  total_trees: "Gesamtzahl der Bäume",

  total_trees_species: "Gesamtzahl der Baumarten",
  total_sensors: "Gesamtzahl der Sensoren",
  critical_sensors: "Gesamtzahl der kritischen Sensoren",
  number_of_tree_on_respective_area: "Anzahl der Bäume auf der jeweiligen Fläche",
  number_of_tree_based_on_health_status: "Anzahl der Bäume basierend auf dem Gesundheitszustand",

  time: "Zeit",
  maintainance: "Wartung",
  type_of_maintenance: "Art der Wartung",
  custom_maintenance_type: "Benutzerdefinierte Wartungsart",
  add_your_own_maintenance: "Eigene Wartung hinzufügen",
  disconnected_sensors: "Getrennte Sensoren",
  Set_the_report_of_the_project_with_the_help_of_the_pin:
    "Stellen Sie den Bericht des Projekts mit Hilfe der Stecknadel ein",
  sending: "Senden...",
  Dry: "Trocken",
  Dryingout: "Am Austrocknen",
  Healthy: "Gesund",
  Unknown: "Unbekannt",
  write_something: "Schreiben Sie etwas...",

  write_some_notes_on_action_performed:
    "Schreiben Sie einige Notizen zur durchgeführten Aktion ...",
  resistance: "Widerstand",
  water_soil: "Bodenfeuchte",
  dendrometer: "Dendrometer",
  irrigation: "Bewässerung",
  crown_care: "Kronenpflege",
  tree_control: "Baumkontrolle",

  weed_removal_root: "Unkrautentfernung Wurzel",
  ligature_control: "Ligaturkontrolle",
  trunk_protection: "Stammschutz",
  tree_stakes_control: "Baumpfahlkontrolle",
  clearance_profile_pruning: "Schnitt des Lichtraumprofils",
  deadwood_removal: "Totholzentfernung",
  stem_cane_removal: "Stammstockentfernung",
  topiary: "Topiary",
  head_purning: "Kronenschnitt",
  either_fill_all_address_or_make_all_empty:
    "Entweder alle Adressen ausfüllen oder alle leer machen",
  search_tree: "Baumsuche",
  search_sensor: "Sensorsuche",
  mark_as_Seen: "Als gesehen markieren",
  tick_the_checkbox_to_download_data: "Checkbox ankreuzen, um Daten herunterzuladen",
  download_sensor_data: "Sensordaten herunterladen",

  sensor_name: "Sensorname",
  temperature: "Temperatur",
  rain: "Regen",
  loading_data: "Daten werden geladen ...",
  align_qr_code_within_the_frame: "QR-Code innerhalb des Rahmens ausrichten",
  gallery: "Galerie",

  scan_to_add_sensor: "Scannen, um Sensor hinzuzufügen",
  choose_qr_to_scan: "QR zum Scannen auswählen",
  pulse_resistance_in_kOhm: "Impulswiderstand in kOhm",
  loading: "Laden ...",
  irrigationArea: "Bewässerungsbereich",
  button: {
    back: "Zurück",
    next: "Weiter",
    save: "Speichern",
    reset: "Zurücksetzen",
    saving: "Speichern ...",
    update: "Aktualisieren",
    updating: "Aktualisieren ...",
    add_tree: "Baum hinzufügen",
    add_sensor: "Sensor hinzufügen",
    import_tree: "Baum importieren",
    add_irrigation_area: "Bewässerungsgebiet hinzufügen",
    add_customer: "Kunde hinzufügen",
    add_all: "Baum mit Sensor erstellen",
  },
  choose_location: "Ort wählen",
  tree_form: {
    sensors: "Sensoren",
    position: "Position",
    more_info: "Weitere Informationen",
    tree_profile: "Baumprofil",
    representTree: "Untergeordnete Bäume",
  },
  user_form: {
    profile: "Profil",
    address: "Adresse",
    basic_info: "Grundlegende Informationen",
    login_credentials: "Anmeldedaten",
    areas: "Bewässerungsbereiche",
  },
  sensor_form: {
    name: "Name",
    threshold: "Schwellenwert",
    hardware_serial: "DevEUI/IMEI",
    sensor_type: "Sensortyp",
    network_type: "Netzwerktyp",
  },
  network_type: "Netzwerktyp",
  sensor_type: "Sensortyp",
  lora_wan: "LoRaWAN",
  nb_iot: "NB-IoT",
  area_form: {
    existing_area: "Bestehender Bereich",
  },
  add_all_form: {
    title: "Baum mit Sensor in Bereich hinzufügen",
    sensor: {
      title: "Sensor",
      explanation:
        "Hier kann ein neuer Sensor erstellt und dem Baum hinzugefügt werden. Oder aber ein bereits bestehender Sensor kann ausgewählt werden, um ihn dem hinzuzufügen.",
      new_sensor: "Erstelle einen neuen Sensor",
      existing_sensors: "Bestehende Sensoren hinzufügen",
    },
    tree: {
      title: "Baum",
      explanation: "Hier kann ein neuer Baum erstellt oder ein bestehender ausgewählt werden.",
      new_tree: "Erstelle einen neuen Baum",
      existing_tree: "Wähle einen bestehenden Baum aus",
    },
    area: {
      title: "Bereich",
      explanation:
        "Hier kann ein neuer Bereich erstellt oder ein bestehender ausgewählt werden, welchem der Baum hinzugefügt werden soll.",
      new_area: "Neuen Bereich erstellen",
      existing_area: "Bestehenden Bereich auswählen",
    },
  },

  new_area: "Neuer Bereich",
  existing_area: "Bestehender Bereich",
  explanation: "Erklärung",
  create_new_tree: "Neuen Baum erstellen",
  validation: {
    email_is_required: "E-Mail ist erforderlich",
    password_is_required: "Passwort ist erforderlich",
    invalid_email_address: "Ungültige E-Mail-Adresse",
    name_is_required: "Name ist erforderlich",

    hardware_serial_is_required: "Hardware-Seriennummer ist erforderlich",
    user_with_this_email_already_exist: "Benutzer mit dieser E-Mail-Adresse existiert bereits",
    all_address_field_are_required: "Alle Adressfelder sind erforderlich",
    position_is_required: "Position ist erforderlich",
    invalid_house_number: "Ungültige Hausnummer",
    user_limit_exceeded_for_your_package: "Benutzerlimit für Ihr Paket überschritten",
    sensor_limit_exceeded_for_your_package: "Sensorlimit für Ihr Paket überschritten",
  },

  "Ensure this field has at least 16 characters.":
    "Stellen Sie sicher, dass das Hardware-Serienfeld mindestens 16 Zeichen hat.",
  drag_the_below_variables_to_graph_area:
    "Ziehen Sie die folgenden Variablen in den Diagrammbereich",
  drop_left: "Drop Left",
  drop_right: "Drop Right",
  drop_the_variable_to_get_data: "Lassen Sie die Variable fallen. Mach weiter!",
  no_data_available_please_check_data_available_to_this_date:
    "Keine Daten verfügbar, bitte überprüfen Sie die bis zu diesem Datum verfügbaren Daten",
  unable_to_upload_file: "Datei kann nicht hochgeladen werden",
  file_upload_successfully: "Datei wurde erfolgreich hochgeladen",
  no_tree_is_selected: "Kein Baum ausgewählt",
  no_sensor_is_selected: "Kein Sensor ausgewählt",

  areaName: "Name",
  childTree: "Untergeordneter Baum",
  add_child_tree: "Untergeordneten Baum hinzufügen",
  parentTree: "Übergeordneter Baum",
  area: "Bereich",
  areas: "Bereiche",
  add_area: "Bereich erstellen",

  start_with_adding_new_project: "Beginnen Sie mit dem Hinzufügen eines neuen Projekts",
  resis_temp_comp: "ResisTempComp",
  health_of_tree_is_good: "Gesundheitszustand des Baums ist gut",
  health_of_tree_is_drying_out: "Gesundheit des Baums trocknet aus",
  health_of_tree_is_poor: "Gesundheit des Baums ist schlecht",
  sensor_disconnect: "Sensor getrennt",
  battery_full: "Batterie voll",
  battery_empty: "Batterie leer",
  maintenance_needed: "Wartung notwendig",
  All: "Alle",

  less_than_60_of_the_battery_remaining: "Weniger als 60 % der verbleibenden Batterie",
  battery_is_getting_low: "Batterie wird schwach",
  size: "Größe",
  type: "Typ",
  map_view: "Kartenansicht",
  records: "Aufzeichnungen",
  status: "Status",
  tree_count: "Anzahl Bäume",

  welcome_back: "Willkommen zurück!",
  looks_like_you_dont_have_selected_area_please_select_the_area_to_get_data:
    "Sieht so aus, als hätten Sie keinen ausgewählten Bereich. Bitte wählen Sie einen Bereich aus, um Daten zu erhalten.",
  looks_like_you_dont_have_selected_tree_please_select_a_tree_to_get_data:
    "Sieht so aus, als hätten Sie keinen Baum ausgewählt. Bitte wählen Sie einen Baum aus, um Daten zu erhalten.",
  looks_like_you_dont_have_selected_sensor_please_select_a_sensor_to_get_data:
    "Sieht so aus, als hätten Sie keinen ausgewählten Sensor. Bitte wählen Sie einen Sensor aus, um Daten abzurufen.",
  please_drag_the_variable_to_display_data: "Bitte ziehen Sie die Variable, um Daten anzuzeigen.",

  click_on_marker_to_select_tree: "Marker anklicken, um Baum auszuwählen",
  next_irrigation: "Nächste Bewässerung",
  mean: "Mittelwert",
  sensor_state: "Sensorstatus",
  state: "Status",
  moisture_content: "Feuchtigkeitsgehalt",
  card_number: "Kartennummer",
  card_name: "Kartenname",

  expiration_date: "Ablaufdatum",
  continue: "Weiter",
  today: "Heute",
  tomorrow: "Morgen",
  days_left: "noch Tage",
  days: "Tage",
  personal_data: "Persönliche Daten",
  appearance: "Aussehen",
  saved: "Gespeichert",
  payment: "Zahlung",
  benifits: "Vorteile",
  change_logo: "Logo ändern",
  role: "Rolle",

  demo_project_warning: {
    title: "Demo Account",
    description:
      "Sie nutzen gerade einen Demo Account und haben nur wenige Rechte.\nWenn Sie bereits Kunde sind und Zugang zu Ihrem Kundenkonto haben möchten, kontaktieren Sie bitte den Support: support@treesense.net.\nFalls Sie noch kein Kunde sind, können Sie über unseren Shop auf https://www.treesense.net Sensoren bestellen.",
  },
  image_upload_info: {
    title: "Upload von Baumbildern möglich",
    description:
      "Um unsere Baumgesundheitsformel auf Ihre Bäume zu optimieren, bieten wir in der Baumliste die Möglichkeit an Bilder von Ihrem Baum hochzuladen.",
  },
  add_tree_img: "Baumbilder hochladen",
  datetime_photo: "Aufnahmezeitpunkt des Bilds",
  upload_tree_img: "Baumbild hochladen",
  upload_leaf_img: "Blattbild hochladen",
  pulse_sensor: "Kronensensor",
  soil_moisture_sensor: "Bodenfeuchtesensor",
  soil_moisture: "Bodenfeuchte",
  soil_water_tension_sensor: "Bodensaugsspannungssensor",
  soil_water_tension: "Bodensaugsspannung",
  dendrometer_sensor: "Dendrometer",
  here: "hier",

  manual: {
    sign_up: {
      title: "Registrierung",
      list: {
        1: "Auf cloud.treesense.net registrieren",
        2: "Dem [Kundensupport](mailto:support@treesense.net) Bescheid geben",
      },
    },
    add_sensor: {
      title: "Anlegen eines Sensors/Projekts",
      list: {
        1: "",
        2: "",
        3: "",
      },
    },
    view_data: {
      title: "Sensordaten ansehen",
      list: {
        1: "",
        2: "",
        3: "",
      },
    },
    irrigation: {
      title: "Nächste Bewässerung",
      list: {
        1: "",
        2: "",
        3: "",
      },
    },
    api: {
      title: "Schnittstelle/API",
      description:
        "Die Dokumentation zur Schnittstelle kann [hier](https://api.treesense.net/docs) aufgerufen werden.",
    },
  },
  views_per_page: "Besuche pro Seite",
  customer_attributes: "Kundenattribute",
  paid: "bezahlt",
  maintenances: "Pflegearbeiten",
  add_maintenance: "Plegearbeit hinzufügen",
  list_maintenances: "Liste bisheriger Wartungen",
  tree_profile: {
    tree_species_knowledge: "Fakten",
    tree_species: "Wissenswertes",
    tree_info: "Bauminformationen",
    want_to_know_more: "Möchten Sie mehr erfahren?",
    learn_more: "Klicken Sie hier, um mehr zu erfahren",
  },
  root_system: {
    UN: "Unbekannt",
    SH: "Flachwurzler",
    DE: "Tiefwurzler",
    HE: "Herzwurzler",
  },
  latin_name: "lat. Name",
  german_name: "dt. Name",
  tree_species: "Baumart",
  depth: "Tiefe",
  healthy: "Gesund",
  not_sending: "Sendet nicht",
  working: "Funktioniert",
  unknown: "Unbekannt",
  amount_nodes: "Anzahl Sensorknoten",
  add_installation: "Installation erstellen",
  select_sensor: "Sensor auswählen",
  select_depth: "Tiefe auswählen",
  installed_at: "Datum der Montage",
  removed_at: "Datum der Demontage",
  last_battery: "Letzter Batteriewert",
};
