import React, { useState, useCallback } from "react";
import { useQuery } from "react-query";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { DataScienceContext } from "./context/DataScienceContext";

import GraphArea from "./graph/GraphArea";
import {
  fetchRainDataByDate,
  fetchTreeHealthByDate,
  fetchTSDataByDate,
  // fetchTSDataLeftMeanByDate,
  fetchTSDataMeanByDate,
} from "@modules/Trees/services/treeCrud";
import EmptyArea from "./components/EmptyArea";
import DropBox from "./components/DropBox";
import { GREEN, YELLOW, RED, GREY, BLUE } from "@helper/colors";

const findSeriesByName = (allResults, name) => {
  return allResults.find((series) => series.name === name);
};

const updateDataPointsOld = (existingDataPoints, newDataPoints) => {
  const dataPointMap = new Map();

  existingDataPoints.forEach((point) => {
    dataPointMap.set(point.value[0], point);
  });

  newDataPoints.forEach((newPoint) => {
    const time = newPoint.value[0];
    const existingPoint = dataPointMap.get(time);

    if (existingPoint) {
      if (
        parseInt(newPoint.interval.replace("s", "").replace("m", "")) <
        parseInt(existingPoint.interval.replace("s", "").replace("m", ""))
      ) {
        dataPointMap.set(time, newPoint);
      }
    } else {
      dataPointMap.set(time, newPoint);
    }
  });

  // return Array.from(dataPointMap.values());
  return Array.from(dataPointMap.values()).sort(
    (a, b) => new Date(a.value[0]) - new Date(b.value[0])
  );
};

const updateDataPoints = (existingData, newDataPoints, startDate, endDate) => {
  if (!newDataPoints || newDataPoints.length === 0) {
    return existingData;
  }

  // Convert startDate and endDate to Date objects for comparison
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Determine the interval of the first new data point
  const newPointInterval = parseInt(newDataPoints[0].interval.replace("s", "").replace("m", ""));

  // Check if the interval of the first new data point is smaller
  const firstExistingPoint = existingData.find(
    (point) => new Date(point.value[0]) >= start && new Date(point.value[0]) <= end
  );
  if (firstExistingPoint) {
    const existingPointInterval = parseInt(
      firstExistingPoint.interval.replace("s", "").replace("m", "")
    );
    if (newPointInterval >= existingPointInterval) {
      return existingData; // Interval is not smaller, return existing data
    }
  }

  // Filter out existing data points that fall within the given time range
  const filteredData = existingData.filter((dataPoint) => {
    const date = new Date(dataPoint.value[0]);
    return date < start || date > end;
  });

  // Merge the new data points into the filtered data
  const updatedData = [...filteredData, ...newDataPoints];

  // Sort the merged data points by time
  updatedData.sort((a, b) => new Date(a.value[0]) - new Date(b.value[0]));

  return updatedData;
};

// const useWaitForHardwareSerials = (tree_sensor_pairs) => {
//   const [ready, setReady] = useState(false);
//
//   useEffect(() => {
//     function checkSerials() {
//       if (tree_sensor_pairs.every((tsp) => tsp.hardware_serial !== undefined)) {
//         setReady(true);
//       } else {
//         setTimeout(checkSerials, 1000); // Check every second
//       }
//     }
//
//     checkSerials();
//   }, [tree_sensor_pairs]);
//
//   return ready;
// };

function GraphContainer() {
  const [rainData, setRainData] = useState([]);
  const query = new URLSearchParams(useLocation().search);
  var tid = query.get("trees");
  const id = query.get("areas");
  // const serials = query.get("sensors");
  const tsid = query.get("treeSensors");
  var serials = tsid;
  if (!tid) {
    if (tsid) {
      tid = tsid
        .split(",")
        .map((tsp) => {
          return tsp.split(" ")[0];
        })
        .join(",");
    }
  }
  if (tsid) {
    serials = tsid
      .split(",")
      .map((tsp) => {
        return tsp.split(" ")[1];
      })
      .join(",");
  }

  const {
    filterState,
    setLYaxis,
    setRYaxis,
    setLYaxisMean,
    setRYaxisMean,
    startDate,
    endDate,
    dragItemLeft,
    dragItemRight,
    positionLeft,
    positionRight,
    activeDate,
    setHealthData,
    lineColor,
    activeMean,
    activeHealthData,
    activeRain,
    // setAllResultsMean,
    // allResultsMean,
  } = useContext(DataScienceContext);
  let { sensorId, tree_sensor_pairs_tree, tree_sensor_pairs, areasId } = filterState;
  // const tree_sensor_pairs = tree_sensor_pairs_tree;
  tree_sensor_pairs = tree_sensor_pairs
    .map((s) => tree_sensor_pairs_tree.find((t) => t.tree === +s.tree && t.sensor === +s.sensor))
    .filter(Boolean);

  const { isLoading: isRainLoading, refetch: refetchRain } = _getRain({
    id,
    startDate,
    endDate,
    activeRain,
    setRainData,
    areasId,
  });

  const { isLoading: isHealthDataLoading, refetch: refetchHealth } = _getHealthData({
    sensorId,
    setHealthData,
    startDate,
    endDate,
    activeHealthData,
  });

  useEffect(() => {
    if (areasId.length == 1 && activeRain == true) {
      refetchRain();
    } else {
      setRainData([]);
    }
  }, [activeDate, activeRain]);
  useEffect(() => {
    if (sensorId.length == 1 && activeHealthData) {
      refetchHealth();
    } else {
      setHealthData([]);
    }
  }, [activeDate, activeHealthData, sensorId]);

  //fetching::begin

  // const ready = useWaitForHardwareSerials(tree_sensor_pairs);
  let checkInitialEmpty = true;
  let checkLoading = false;
  // If hardware_serials are not ready yet, don't run _getTsDataLeft
  // var ready = false;
  // if (tree_sensor_pairs.every((item) => item.hardware_serial != undefined)) {
  //   ready = true;
  // }

  // if (ready) {
  const {
    isLoading: isLeftLoading,
    isFetching: isLeftFetching,
    refetch: refetchLeft,
  } = _getTsData({
    tree_sensor_pairs,
    setYAxis: setLYaxis,
    position: positionLeft,
    dragItem: dragItemLeft,
    startDate,
    endDate,
    lineColor,
  });
  const {
    isLoading: isRightLoading,
    refetch: refetchRight,
    isFetching: isRightFetching,
  } = _getTsData({
    tree_sensor_pairs,
    setYAxis: setRYaxis,
    dragItem: dragItemRight,
    postion: positionRight,
    startDate,
    endDate,
    lineColor,
  });

  ///mean
  const {
    isLoading: isLeftMeanLoading,
    isFetching: isLeftMeanFetching,
    refetch: refetchMeanLeft,
  } = _getTsData({
    tree_sensor_pairs,
    setYAxis: setLYaxisMean,
    position: positionLeft,
    dragItem: dragItemLeft,
    startDate,
    endDate,
    lineColor,
    // mean: true,
    mean: activeMean,
  });
  const {
    isLoading: isRightMeanLoading,
    isFetching: isRightMeanFetching,
    refetch: refetchMeanRight,
  } = _getTsData({
    tree_sensor_pairs,
    setYAxis: setRYaxisMean,
    dragItem: dragItemRight,
    position: positionRight,
    startDate,
    endDate,
    lineColor,
    // mean: true,
    mean: activeMean,
  });
  // refetch:begin;
  useEffect(() => {
    if (tree_sensor_pairs.length > 0 && positionLeft == true) {
      refetchLeft();
    }
  }, [activeDate, tree_sensor_pairs.toString(), dragItemLeft, startDate, endDate]);

  useEffect(() => {
    if (tree_sensor_pairs.length > 0 && positionRight == true) {
      refetchRight();
    }
  }, [activeDate, tree_sensor_pairs.toString(), dragItemRight, startDate, endDate]);

  useEffect(() => {
    if (tree_sensor_pairs.length > 0 && positionLeft == true && activeMean === true) {
      refetchMeanLeft();
    }
  }, [activeDate, tree_sensor_pairs.toString(), dragItemLeft, activeMean, startDate, endDate]);

  useEffect(() => {
    if (tree_sensor_pairs.length > 0 && positionRight == true && activeMean === true) {
      refetchMeanRight();
    }
  }, [activeDate, tree_sensor_pairs.toString(), dragItemRight, activeMean, startDate, endDate]);

  // refetch::end

  checkInitialEmpty = !id || !tid || !serials || (!dragItemRight && !dragItemLeft) ? true : false;

  checkLoading =
    isRightLoading ||
    isLeftLoading ||
    isLeftFetching ||
    isRightFetching ||
    isLeftMeanLoading ||
    isLeftMeanFetching ||
    isRightMeanLoading ||
    isRightMeanFetching ||
    isHealthDataLoading ||
    isRainLoading
      ? true
      : false;
  return (
    <div className="position-relative ds graph">
      {checkInitialEmpty ? (
        <>
          <DropBox />
          <EmptyArea tid={tid} dragItem={dragItemLeft} id={id} serial={serials} />
        </>
      ) : (
        <>
          {checkLoading && <Spin />}
          <>
            <DropBox />
            {<GraphArea checkLoading={checkLoading} rainData={rainData} />}
          </>
        </>
      )}
    </div>
  );
}

export default GraphContainer;

const Spin = () => {
  return (
    <div className="spin">
      <Spinner animation="border" variant="primary" style={{ width: "3rem", height: "3rem" }} />
    </div>
  );
};

function getInterval(startDate, endDate) {
  const startDt = new Date(startDate);
  const endDt = new Date(endDate);
  const duration = endDt - startDt;
  const threeWeeks = 1814400000;
  // const sixMonths = 15724800000;
  const interval = `${Math.floor((duration * 30) / threeWeeks)}m`;
  if (interval == "0m") return "1s";
  return interval;
}

function chunkArray(array, size) {
  if (array.length == 0) return [[]];
  const chunkedArr = [];
  for (let i = 0; i < array.length; i += size) {
    chunkedArr.push(array.slice(i, i + size));
  }
  return chunkedArr;
}

function _getTsData({
  tree_sensor_pairs,
  dragItem,
  position,
  startDate,
  endDate,
  lineColor,
  setYAxis,
  mean,
}) {
  const [allResults, setAllResults] = useState([]);
  const [allResultsMean, setAllResultsMean] = useState([]);
  const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
  const [enabled, setEnabled] = useState(true);
  const chunks = chunkArray(tree_sensor_pairs, 5);

  const currentChunk = chunks[currentChunkIndex];
  const tsps =
    currentChunk && currentChunk.length > 0
      ? currentChunk.map((tsp) => `${tsp.tree}+${tsp.sensor}`)
      : [];
  const tree_sensors_joined = tsps.join(",");
  const isEnabled =
    tsps.length > 0 &&
    mean !== false &&
    enabled &&
    currentChunk &&
    currentChunk.length > 0 &&
    [
      "resistance",
      "temperature",
      "soil_moisture",
      "soil_water_tension",
      "resis_temp_comp",
      "moisture_content",
    ].includes(dragItem) &&
    position;

  const { data, isSuccess, refetch, isLoading, isFetching } = useQuery(
    [`${dragItem}+${tree_sensors_joined}`, startDate, endDate, mean],
    () =>
      fetchTSDataByDate({
        tsps: tree_sensors_joined,
        startDate,
        endDate,
        field: dragItem,
        interval: mean ? "1d" : getInterval(startDate, endDate),
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: isEnabled,
    }
  );

  useEffect(() => {
    if (isSuccess && enabled) {
      let result = [];
      if (mean === true) {
        result = [...allResultsMean];
      } else {
        result = [...allResults];
      }
      currentChunk?.forEach((tsp) => {
        const seriesName = `${tsp.tree_name ?? tsp.tree} ${tsp.sensor_name ?? tsp.sensor}`;
        let series = findSeriesByName(allResults, seriesName);

        const interval = getInterval(startDate, endDate);
        const newData =
          data[`${tsp.tree} ${tsp.sensor}`]?.map((value) => ({
            type: dragItem,
            value: [value["time"], value[dragItem]],
            color: lineColor[tsp.tree + " " + tsp.sensor],
            tsp: tsp,
            interval: interval,
          })) || [];

        let label = dragItem;
        let color = lineColor[tsp.tree + " " + tsp.sensor];
        if (mean) {
          label += "-mean";
          color += 50;
        }

        if (series) {
          series.data = updateDataPoints(series.data, newData);
        } else {
          result.push({
            name: seriesName,
            type: "line",
            label: label,
            tsp: tsp,
            color: color,
            data: newData,
          });
        }
      });
      if (mean === true) {
        setAllResultsMean(result);
        const allResultsFiltered = allResultsMean.filter(
          (item) =>
            // (tree_sensor_pairs.includes(item.tsp) &&
            // item.label === dragItem + "-mean"
            tree_sensor_pairs.some(
              (tsp) => tsp.tree === item.tsp.tree && tsp.sensor === item.tsp.sensor
            ) && item.label === dragItem + "-mean" // || item.label === dragItem + "-mean" //) ||
          // tree_sensor_pairs.length === 0
        );
        setYAxis(allResultsFiltered);
      } else {
        // console.log("AR; TSPS", allResults, tree_sensor_pairs);
        const allResultsFiltered = result.filter(
          (item) =>
            tree_sensor_pairs.some(
              (tsp) => tsp.tree === item.tsp.tree && tsp.sensor === item.tsp.sensor
            ) && item.label === dragItem // || item.label === dragItem + "-mean" //) ||
          // tree_sensor_pairs.length === 0
        );
        setAllResults(result);
        // setYAxis(result);
        setYAxis(allResultsFiltered);
      }
      // setYAxis(allResultsFiltered);
      // setYAxis([...allResults, ...allResultsMean]);
      // console.log("ARF", currentChunk, allResultsFiltered);
      // }

      if (currentChunkIndex < chunks.length - 1) {
        setCurrentChunkIndex((prevIndex) => prevIndex + 1);
      } else {
        // console.log(currentChunk, "DISABLING", dragItem);
        if (isEnabled) {
          setEnabled(false);
        }
      }
    }
  }, [isSuccess, data, currentChunk]);

  // useEffect(() => {
  //   setLYaxis(allResults);
  //   // if (currentChunkIndex >= chunks.length) {
  //   //   console.log("aR", allResults);
  //   //   setLYaxis(allResults);
  //   // }
  // }, [allResults]);

  const refetchAll = useCallback(() => {
    // setAllResults([]);
    setCurrentChunkIndex(0);
    // console.log("SETTING INDEX TO 0");
    setEnabled(true);
    refetch();
  }, [refetch]);
  // const refetchAll = useCallback(() => console.log("FETCH"));

  return { allResults, refetch: refetchAll, isLoading: isLoading, isFetching: isFetching };
}

// D.Sensor health
function _getHealthData({ sensorId, setHealthData, startDate, endDate, activeHealthData }) {
  return useQuery(
    "healthState",
    () => fetchTreeHealthByDate({ id: sensorId, startDate, endDate }),
    {
      refetchOnWindowFocus: false,
      enabled: !!activeHealthData,
      onSuccess: (data) => {
        let result = [];
        let b = data?.time?.map((value, i) => {
          let color = "";
          if (data.states[i] == 0) {
            color = GREEN;
          }
          if (data.states[i] == 1) {
            color = YELLOW;
          }
          if (data.states[i] == 2) {
            color = RED;
          }
          if (data.states[i] == 3) {
            color = GREY;
          }
          if (data.states[i] == 4) {
            color = BLUE;
          }
          return [
            {
              value: [value, 1],
              itemStyle: {
                color: color,
              },
            },
          ];
        });

        result.push({
          name: "health",
          type: "bar",
          yAxisIndex: 2,
          barCategoryGap: -50,
          large: true,
          barWidth: 50,
          data: b ? b.flat() : [],
        });
        setHealthData(result);
      },
    }
  );
}

// E.Rain data
function _getRain({ id, activeRain, setRainData, startDate, endDate, areasId }) {
  return useQuery("rain", () => fetchRainDataByDate({ id, startDate, endDate }), {
    refetchOnWindowFocus: false,
    enabled: activeRain && areasId.length == 1,
    onSuccess: (data) => {
      const { rain } = data;
      let res = [];
      const b = rain?.x?.map((value, i) => {
        return [
          {
            value: [value, rain?.y[i]],
            color: "#388090",
          },
        ];
      });
      res.push({
        name: "rain",
        type: "line",
        smooth: true,
        showSymbol: false,
        yAxisIndex: 3,
        barCategoryGap: -50,
        color: "#388090",
        areaStyle: { color: "#388090" },
        data: b ? b.flat() : [],
      });
      activeRain && setRainData(res);
    },
  });
}
