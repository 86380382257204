export const TRANSLATIONS_IT = {
  email: "E-Mail",
  password: "Password",
  forgotpassword: "Hai dimenticato la password?",
  email_field_is_required: "Il campo e-mail è obbligatorio",
  reset_password: "Reimposta password",
  password_field_is_required: "Il campo password è obbligatorio",
  password_do_not_match: "La password non corrisponde",
  invalid_email_address: "Indirizzo e-mail non valido",
  enter_email_address: "Inserire indirizzo e-mail",
  create_account: "Crea un account",
  dont_have_an_account: "Non hai un account?",
  already_have_an_account: "Hai già un account?",
  back_to_login: "Torna al login",
  incorrect_email_or_password: "E-mail o password errata",
  enter_the_email_address_associated_with_your_account_and_we_will_send_you_a_link_to_reset_your_password:
    "Inserisci l'indirizzo e-mail associato al tuo account e ti invieremo un link per reimpostare la tua password.",
  dashboard: {
    title: "Dashboard",
    view_trees: "Visualizza alberi",
    view_sensors: "Visualizza sensori",
  },
  newproject: "Nuovo progetto",
  logout: "Disconnettersi",
  viewproject: "Visualizza progetto",
  createproject: "Crea progetto",
  projectname: "Nome del progetto",
  selecttree: "Seleziona albero",
  user: "Utente",
  users: "Utenti",
  username: "Nome utente",
  treereference: "Riferimento albero",
  child_trees: "Alberi rappresentativi",
  sortoftree: "Tipo di albero",
  save: "Salva",
  position: "Posizione",
  Notes: "Note",
  addTree: "Aggiungi albero",
  TreeType: "Tipo di albero",
  old: "Vecchio",
  new: "Nuovo",
  name: "Nome",
  image: "Immagine",
  abort: "Annulla",
  next: "Avanti",
  back: "Indietro",
  update: "Aggiorna",
  login: "Accedi",
  signup: "Registrati",
  date_of_planting: "Data di piantagione",
  supplies: "Forniture",
  lightdry: "Leggermente asciutto",
  addTreeType: "Aggiungi tipo di albero",
  delete: "Elimina",
  deleting: "Eliminando ...",
  saving: "Salvando ...",
  updating: "Aggiornando ...",
  edit: "Modifica",
  project: "Progetto",
  projects: "Progetti",
  add: "Aggiungi",
  trees: "Alberi",
  tree: "Albero",
  choose_tree: "Scegli un albero",
  customers: "Clienti",
  customer: "Cliente",
  address: "Indirizzo",
  confirm: "Conferma",
  no_connection: "Nessuna connessione",
  battery_status: "Stato della batteria",
  graph: "Grafico",
  page: "Pagina",
  health_state: "Stato salute dell'albero",
  last_send: "Ultimo invio",
  last_value: "Ultimo valore",
  start_date: "Data di inizio",
  end_date: "Data di fine",
  send: "Invia",
  weather: "Meteo",
  project_details: {
    empty_project: "Progetto vuoto, inizia aggiungendo alberi.",
    empty_trees: "Nessun albero creato, inizia aggiungendo alberi.",
    no_tree_selected: "Seleziona un albero per mostrare i dettagli",
    empty_location: "Nessuna posizione trovata, aggiungi una posizione",
    no_area_selected: "Seleziona un'area per mostrare i dettagli",
  },
  user_details: {
    empty_project: "Nessun utente, inizia aggiungendo alberi.",
    no_user_selected: "Seleziona un utente per mostrare i dettagli",
  },
  sensor_details: {
    empty_sensors: "Nessun sensore, inizia aggiungendo sensori.",
    no_sensor_selected: "Seleziona un sensore per mostrare i dettagli",
    no_data_found: "Nessun dato sensore trovato",
  },
  sensor: "Sensore",
  sensors: "Sensori",
  sensor_data: "Dati del sensore",
  species: "Specie",
  genus: "Genere",
  actions: "Azioni",
  battery: "Batteria",
  threshold: "Soglia",
  settings: "Impostazioni",
  notifications: "Notifiche",
  mark_all_as_seen: "Segna tutti come letti",
  showing: "Mostrando",
  // hardware_serial: "Numero di serie hardware",
  // hardware_serial_no: "No. di serie hardware",
  hardware_serial: "DevEUI",
  imei: "IMEI",
  add_sensor: "Aggiungi sensore",
  add_user: "Aggiungi utente",
  edit_user: "Modifica utente",
  edit_sensor: "Modifica sensore",
  push_message_notification: "Notifiche push",
  email_notification: "Notifiche via email",
  sensor_status_change_to_no_connection: "Cambio stato sensore a 'nessuna connessione'",
  sensor_status_change_to_dry: "Cambio stato sensore a 'secco'",
  sensor_status_change_to_drying_out: "Cambio stato sensore a 'in via di asciugatura'",
  treespecies: "Specie di albero",
  treegenus: "Genere di albero",
  skip: "Salta",
  notes: "Note",
  latitude: "Latitudine",
  longitude: "Longitudine",
  trunk_circumference: "Circonferenza del tronco",
  crown_diameter: "Diametro della chioma",
  represent_tree: "Rappresenta albero",
  planting_time: "Tempo di piantagione",
  altitude: "Altitudine",
  height: "Altezza",
  location: "Posizione",
  reference: "Riferimento",
  invalid_date: "Data non valida",
  error: {
    this_field_is_required: "Questo campo è obbligatorio",
    name_field_is_required: "Il campo nome è obbligatorio",
    please_choose_species: "Si prega di scegliere la specie",
    please_choose_genus: "Si prega di scegliere il genere",
    choose_location: "Scegli la posizione",
    hardware_serial_no_is_required: "Il numero di serie hardware è obbligatorio",
    a_valid_threshold_is_required: "È necessaria una soglia valida",
    form: "I valori sono stati inseriti in modo errato. Correggerli e riprovare",
  },
  first_name: "Nome",
  last_name: "Cognome",
  profile: "Profilo",
  Profile: "Profilo",
  Settings: "Impostazioni",
  Manual: "Manuale",
  of: "di",
  the: "il/la",
  Menu: "Menu",
  phone_number: "Numero di telefono",
  account_type: "Tipo di account",
  city: "Città",
  street_name: "Nome della strada",
  house_number: "Numero civico",
  country: "Paese",
  zip_code: "Codice postale",
  day: {
    mon: "Lunedì",
    tue: "Martedì",
    wed: "Mercoledì",
    thu: "Giovedì",
    fri: "Venerdì",
    sat: "Sabato",
    sun: "Domenica",
  },
  end: "Fine",
  admin: "Admin",
  basic: "Base",
  superuser: "Superutente",
  add_existing_tree: "Aggiungi albero esistente",
  phase: "Fase",
  health_states: {
    healthy: "Sano",
    medium: "Medio",
    critical: "Critico",
    frost: "Gelo",
    dry: "Secco",
    disconnect: "Disconnetti",
    drying_out: "In via di asciugatura",
    unknown: "Sconosciuto",
  },
  tree_cadastre_no: "No. catasto albero",
  export: "Esporta",
  problem_in_creating_customer: "Problema nella creazione del cliente",
  problem_in_creating_sensor: "Problema nella creazione del sensore",
  select_customer_type: "Seleziona tipo di cliente",
  assign_to_tree: "Assegna all'albero",
  customer_type: "Tipo di cliente",
  import_trees: "Importa alberi",
  choose_file: "Scegli file",
  upload: "Carica",
  choose_the_file_to_be_imported: "Scegli il file da importare",
  upload_tree: "Carica albero",
  change_image: "Cambia immagine",
  tree_added_successfully: "Albero aggiunto con successo",
  change_language: "Cambia lingua",
  total_trees: "Totale alberi",
  total_trees_species: "Totale specie di alberi",
  total_sensors: "Totale sensori",
  critical_sensors: "Sensori critici",
  number_of_tree_on_respective_area: "Numero di alberi nell'area rispettiva",
  number_of_tree_based_on_health_status: "Numero di alberi in base allo stato di salute",
  time: "Tempo",
  maintainance: "Manutenzione",
  type_of_maintenance: "Tipo di manutenzione",
  custom_maintenance_type: "Tipo di manutenzione personalizzato",
  add_your_own_maintenance: "Aggiungi la tua manutenzione",
  disconnected_sensors: "Sensori disconnessi",
  Set_the_report_of_the_project_with_the_help_of_the_pin:
    "Imposta il rapporto del progetto con l'aiuto della spilla",
  sending: "Invio...",
  Dry: "Secco",
  Dryingout: "In via di asciugatura",
  Healthy: "Sano",
  Unknown: "Sconosciuto",
  write_something: "Scrivi qualcosa...",
  write_some_notes_on_action_performed: "Scrivi alcune note sull'azione eseguita...",
  resistance: "Resistenza",
  water_soil: "Umidità del suolo",
  dendrometer: "Dendrometro",
  irrigation: "Irrigazione",
  crown_care: "Cura della chioma",
  tree_control: "Controllo dell'albero",
  weed_removal_root: "Rimozione delle erbacce dalla radice",
  ligature_control: "Controllo delle legature",
  trunk_protection: "Protezione del tronco",
  tree_stakes_control: "Controllo dei pali dell'albero",
  clearance_profile_pruning: "Potatura del profilo di sgombero",
  deadwood_removal: "Rimozione del legno morto",
  stem_cane_removal: "Rimozione dei gambi",
  topiary: "Topiaria",
  head_purning: "Potatura della testa",
  either_fill_all_address_or_make_all_empty:
    "Riempire tutti i campi dell'indirizzo o lasciarli tutti vuoti",
  search_tree: "Cerca albero",
  search_sensor: "Cerca sensore",
  mark_as_Seen: "Segna come Visto",
  tick_the_checkbox_to_download_data: "Seleziona la casella per scaricare i dati",
  download_sensor_data: "Scarica dati del sensore",
  sensor_name: "Nome del sensore",
  temperature: "Temperatura",
  rain: "Pioggia",
  loading_data: "Caricamento dati...",
  align_qr_code_within_the_frame: "Allinea il codice QR all'interno del riquadro",
  gallery: "Galleria",
  scan_to_add_sensor: "Scansiona per aggiungere sensore",
  choose_qr_to_scan: "Scegli QR da scansionare",
  pulse_resistance_in_kOhm: "Resistenza agli impulsi in kOhm",
  loading: "Caricamento...",
  irrigationArea: "Area di irrigazione",
  button: {
    back: "Indietro",
    next: "Avanti",
    save: "Salva",
    reset: "Reimposta",
    saving: "Salvando...",
    update: "Aggiorna",
    updating: "Aggiornando...",
    add_tree: "Aggiungi albero",
    add_sensor: "Aggiungi sensore",
    import_tree: "Importa albero",
    add_irrigation_area: "Aggiungi area di irrigazione",
    add_customer: "Aggiungi cliente",
    add_all: "Aggiungi tutto",
  },
  choose_location: "Scegli posizione",
  tree_form: {
    sensors: "Sensori",
    position: "Posizione",
    more_info: "Maggiori informazioni",
    tree_profile: "Profilo dell'albero",
    representTree: "Rappresenta Alberi",
  },
  user_form: {
    profile: "Profilo",
    address: "Indirizzo",
    basic_info: "Informazioni di base",
    login_credentials: "Credenziali di accesso",
    areas: "Aree",
  },
  sensor_form: {
    name: "Nome",
    threshold: "Soglia",
    hardware_serial: "Numero di serie hardware",
    sensor_type: "Tipo di sensore",
  },
  area_form: {
    existing_area: "Area esistente",
  },
  add_all_form: {
    title: "Aggiungi Albero e Sensore in Area",
    sensor: {
      title: "Sensore",
      explanation:
        "Qui puoi creare un nuovo sensore da aggiungere all'albero, oppure selezionare un sensore esistente da aggiungere.",
      new_sensor: "Crea un nuovo sensore",
      existing_sensors: "Aggiungi sensori esistenti",
    },
    tree: {
      title: "Albero",
      explanation: "Qui puoi creare un nuovo albero o selezionarne uno esistente.",
      new_tree: "Crea un nuovo albero",
      existing_tree: "Seleziona un albero esistente",
    },
    area: {
      title: "Area",
      explanation:
        "Qui puoi creare una nuova area o selezionarne una esistente a cui aggiungere l'albero.",
      new_area: "Crea una nuova area",
      existing_area: "Seleziona un'area esistente",
    },
  },
  new_area: "Nuova area",
  existing_area: "Area esistente",
  explanation: "Spiegazione",
  create_new_tree: "Crea nuovo albero",
  validation: {
    email_is_required: "L'email è richiesta",
    password_is_required: "La password è richiesta",
    invalid_email_address: "Indirizzo email non valido",
    name_is_required: "Il nome è richiesto",
    hardware_serial_is_required: "Il numero di serie hardware è richiesto",
    user_with_this_email_already_exist: "Un utente con questa email esiste già",
    all_address_field_are_required: "Tutti i campi dell'indirizzo sono richiesti",
    position_is_required: "La posizione è richiesta",
    invalid_house_number: "Numero di casa non valido",
    user_limit_exceeded_for_your_package: "Limite di utenti superato per il vostro pacchetto",
    sensor_limit_exceeded_for_your_package: "Superamento del limite del sensore per il pacchetto",
  },
  "Ensure this field has at least 16 characters.":
    "Assicurati che questo campo abbia almeno 16 caratteri.",
  drag_the_below_variables_to_graph_area: "Trascina le variabili sottostanti nell'area del grafico",
  drop_left: "Rilascia a sinistra",
  drop_right: "Rilascia a destra",
  drop_the_variable_to_get_data: "Rilascia la variabile per ottenere i dati",
  no_data_available_please_check_data_available_to_this_date:
    "Nessun dato disponibile, si prega di controllare i dati disponibili a questa data",
  unable_to_upload_file: "Impossibile caricare il file",
  file_upload_successfully: "File caricato con successo",
  no_tree_is_selected: "Nessun albero selezionato",
  no_sensor_is_selected: "Nessun sensore selezionato",
  areaName: "Nome dell'area",
  childTree: "Albero secondario",
  add_child_tree: "Aggiungi albero secondario",
  parentTree: "Albero principale",
  area: "Area",
  areas: "Aree",
  add_area: "Aggiungi area",
  start_with_adding_new_project: "Inizia aggiungendo un nuovo progetto",
  resis_temp_comp: "Compensazione temperatura resis",
  health_of_tree_is_good: "La salute dell'albero è buona",
  health_of_tree_is_drying_out: "La salute dell'albero si sta esaurendo",
  health_of_tree_is_poor: "La salute dell'albero è scarsa",
  sensor_disconnect: "Sensore disconnesso",
  battery_full: "Batteria piena",
  less_than_60_of_the_battery_remaining: "Meno del 60% della batteria rimanente",
  battery_is_getting_low: "La batteria si sta esaurendo",
  size: "Dimensione",
  type: "Tipo",
  map_view: "Vista mappa",
  records: "Record",
  status: "Stato",
  welcome_back: "Bentornato!",
  looks_like_you_dont_have_selected_area_please_select_the_area_to_get_data:
    "Sembra che tu non abbia selezionato un'area, seleziona un'area per ottenere i dati",
  looks_like_you_dont_have_selected_tree_please_select_a_tree_to_get_data:
    "Sembra che tu non abbia selezionato un albero, seleziona un albero per ottenere i dati",
  looks_like_you_dont_have_selected_sensor_please_select_a_sensor_to_get_data:
    "Sembra che tu non abbia selezionato un sensore, seleziona un sensore per ottenere i dati",
  please_drag_the_variable_to_display_data: "Trascina la variabile per visualizzare i dati",
  click_on_marker_to_select_tree: "Clicca sul marcatore per selezionare l'albero",
  next_irrigation: "Prossima irrigazione",
  mean: "Media",
  sensor_state: "Stato del sensore",
  state: "Stato",
  moisture_content: "Contenuto di umidità",
  card_number: "Numero della carta",
  card_name: "Nome sulla carta",
  expiration_date: "Data di scadenza",
  continue: "Continua",
  today: "Oggi",
  tomorrow: "Domani",
  days_left: "giorni rimasti",
  days: "Giorni",
  personal_data: "Dati personali",
  appearance: "Aspetto",
  saved: "Salvato",
  payment: "Pagamento",
  benifits: "Benefici",
  change_logo: "Cambia logo",
  role: "Ruolo",
  demo_project_warning: {
    title: "Account Demo",
    description:
      "Stai utilizzando un account demo e hai diritti limitati. Se sei già un cliente e desideri accedere al tuo account cliente, contatta il supporto all'indirizzo support@treesense.net. Se non sei ancora un cliente, puoi ordinare sensori tramite il nostro negozio su https://www.treesense.net.",
  },
  image_upload_info: {
    title: "Caricamento di immagini degli alberi possibile",
    description:
      "Per ottimizzare la nostra formula di salute dell'albero sui tuoi alberi, offriamo la possibilità di caricare immagini del tuo albero nell'elenco degli alberi.",
  },
  add_tree_img: "Carica immagini dell'albero",
  datetime_photo: "Data e ora della foto",
  upload_tree_img: "Carica immagine dell'albero",
  upload_leaf_img: "Carica immagine della foglia",
  pulse_sensor: "Sensore a impulsi",
  soil_moisture_sensor: "Sensore di umidità del suolo",
  dendrometer_sensor: "Dendrometro",
  here: "qui",
  // amount_nodes: "Amount sensor nodes",
};
